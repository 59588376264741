<template>
  <div>
    <b-modal
      id="modal-1"
      :title="msgModaleTitle"
      ref="msg-modal"
      ok-only
      :no-close-on-backdrop="true"
    >
      <p class="my-4">{{ msg }}</p>
    </b-modal>
    <v-data-table
      :headers="tplQuesSectHeaders"
      :items="tplQuesSectData"
      item-key="tpl_ques_id"
      :search="search"
      class="elevation-1"
      style="font-family: Poppins, Helvetica: sans-serif"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Template Questions</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-dialog v-model="dialog" max-width="75%">
            <v-card>
              <v-card-title>
                <span class="headline">Template Questions</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >Template No</v-col
                    >
                    <v-col cols="12" sm="9">{{ editedItem.tpl_id }}</v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >CC Question Number</v-col
                    >
                    <v-col cols="12" sm="9">{{ editedItem.cc_ques_no }}</v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >CC Integrated Reference</v-col
                    >
                    <v-col cols="12" sm="9">{{ editedItem.cc_mapping }}</v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >Questionnaire</v-col
                    >
                    <v-col cols="12" sm="9">{{
                      editedItem.questionnaire
                    }}</v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >Topic</v-col
                    >
                    <v-col cols="12" sm="9">{{ editedItem.topic }}</v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >Question</v-col
                    >
                    <v-col cols="12" sm="9">{{ editedItem.ques_text }}</v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >Excerpt</v-col
                    >
                    <v-col cols="12" sm="9">{{ editedItem.excerpt }}</v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >Evidence Expires in</v-col
                    >
                    <v-col cols="12" sm="9">{{ editedItem.expiry_date }}</v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >Critical Question</v-col
                    >
                    <v-col cols="12" sm="9">{{
                      editedItem.critical_ques
                    }}</v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >Regulations</v-col
                    >
                    <v-col cols="12" sm="9">
                      <ul class="list-unstyled p-0 m-0">
                        <li
                          v-for="regulation in editedItem.regulations"
                          v-bind:key="regulation"
                        >
                          {{ regulation }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >Platforms</v-col
                    >
                    <v-col cols="12" sm="9">
                      <ul class="list-unstyled p-0 m-0">
                        <li
                          v-for="platform in editedItem.platforms"
                          v-bind:key="platform"
                        >
                          {{ platform }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >Environments</v-col
                    >
                    <v-col cols="12" sm="9">
                      <ul class="list-unstyled p-0 m-0">
                        <li
                          v-for="environment in editedItem.environments"
                          v-bind:key="environment"
                        >
                          {{ environment }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >Tags</v-col
                    >
                    <v-col cols="12" sm="9">
                      <ul class="list-unstyled p-0 m-0">
                        <li v-for="tag in editedItem.tags" v-bind:key="tag">
                          {{ tag }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >Question Attachment Text</v-col
                    >
                    <v-col cols="12" sm="9">{{ editedItem.help_text }}</v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >Control Numbers</v-col
                    >
                    <v-col cols="12" sm="9">
                      <p
                        v-for="ctrl in editedItem.controls"
                        v-bind:key="ctrl.regln_name"
                      >
                        <span class="font-weight-bold"
                          >{{ ctrl.regln_name }} :</span
                        >
                        &nbsp; {{ ctrl.ctrl_no }}
                      </p>
                    </v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold"
                      >Field Data</v-col
                    >
                    <v-col cols="12" sm="9">
                      <ul class="list-unstyled p-0 m-0">
                        <li
                          v-for="field_detail in editedItem.field_details"
                          v-bind:key="field_detail"
                        >
                          {{ field_detail }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-b-tooltip.hover.bottom="'View Question Details'"
          small
          class="mr-2"
          @click="viewTplQues(item)"
        >
          info
        </v-icon>
      </template>
      <template v-slot:no-data></template>
    </v-data-table>
  </div>
</template>

<script>
import CCApiService from "@/core/services/aws.ccapi.service";

export default {
  data() {
    return {
      tpl_id: this.$route.params.id,
      e1: 1,
      show: true,
      errors: [],
      msgModaleTitle: "",
      msg: "",
      dialog: false,
      dialogTplQuesEdit: false,
      search: "",
      singleSelect: false,
      selected: [],
      editTplQuesHeaders: [],
      tplQuesSectHeaders: [],
      tplQuesData: [],
      tplQuesSectData: [],
      loading: false,
      responses: [],
      editedItem: {
        tpl_id: 0,
        tpl_ques_id: 0,
        qm_ques_id: 0,
        cc_mapping: "",
        questionnaire: "",
        cc_ques_no: "",
        topic: "",
        section: "",
        ques_text: "",
        excerpt: "",
        expiry_date: "",
        critical_ques: 0,
        help_text: "",
        tag: "",
        regulation: "",
        platform: "",
        environment: "",
        controls: "",
        field_detail: ""
      }
    };
  },
  components: {},
  beforeMount() {
    this.tpl_id = localStorage.getItem("tpl_id");

    if (this.tpl_id > 0) {
      //this.getTemplates();
      this.getTemplateQuestionList();
    }
  },
  created() {
    localStorage.setItem("tpl_ques_id", 0);
    this.initialize();
  },
  mounted() {},
  computed: {},
  methods: {
    close() {
      this.dialog = false;
      this.dialogTplQuesEdit = false;
      this.$nextTick(() => {});
    },
    initialize() {
      this.tplQuesSectHeaders = [
        {
          text: "#",
          align: "left",
          sortable: false,
          value: "tpl_ques_id",
          width: "5%"
        },
        { text: "CC Question Number", value: "cc_ques_no", width: "10%" },
        { text: "CC Integrated Reference", value: "cc_mapping", width: "10%" },
        { text: "Topic", value: "topic", width: "10%" },
        { text: "Question", value: "ques_text", width: "45%" },
        { text: "Section", value: "section", width: "10%" },
        { text: "Actions", value: "actions", sortable: false, width: "10%" }
      ];
      this.editTplQuesHeaders = [
        {
          text: "#",
          align: "left",
          sortable: false,
          value: "tpl_ques_id",
          width: "5%"
        },
        { text: "CC Question Number", value: "cc_ques_no", width: "10%" },
        { text: "CC Integrated Reference", value: "cc_mapping", width: "10%" },
        { text: "Topic", value: "topic", width: "10%" },
        { text: "Question", value: "ques_text", width: "45%" },
        { text: "Section", value: "section", width: "10%" },
        { text: "Actions", value: "actions", sortable: false, width: "10%" }
      ];
      this.getTemplateQuestionList();
    },

    showMsg: function(type, msg) {
      this.msgModaleTitle = type == "error" ? "Error" : "Message";
      this.msg = msg;
      this.$refs["msg-modal"].show();
    },
    getTemplateQuestionList: function() {
      const apiName = "OneAudit";
      const path = "/templates/" + this.tpl_id + "/questions";
      this.loading = true;
      CCApiService.get(apiName, path)
        .then(response => {
          this.tplQuesSectData = [];
          if (response != undefined && response.hasOwnProperty("error")) {
            if (response.error == "false") {
              if (response.hasOwnProperty("data") && response.data.length > 0) {
                response = response.data;
                for (var index in response) {
                  var environmentNames = [];
                  var regulationNames = [];
                  var platformNames = [];
                  var tagNames = [];
                  var fieldDetails = [];
                  var controlNames = [];

                  if (response[index].environments != undefined) {
                    for (var i in response[index].environments) {
                      environmentNames.push(
                        response[index].environments[i].env_name
                      );
                    }
                  }

                  if (response[index].regulations != undefined) {
                    for (var j in response[index].regulations) {
                      regulationNames.push(
                        response[index].regulations[j].regln_name
                      );
                    }
                  }

                  if (response[index].controls != undefined) {
                    for (var x in response[index].controls) {
                      controlNames.push({
                        regln_name: response[index].controls[x].regln_name,
                        ctrl_no: response[index].controls[x][
                          "ctrl_no"
                        ].toString()
                      });
                    }
                  }

                  if (response[index].platforms != undefined) {
                    for (var k in response[index].platforms) {
                      platformNames.push(
                        response[index].platforms[k].plat_name
                      );
                    }
                  }

                  if (response[index].tags != undefined) {
                    for (var t in response[index].tags) {
                      tagNames.push(response[index].tags[t].tag_name);
                    }
                  }

                  if (response[index].field_details != undefined) {
                    for (var u in response[index].field_details) {
                      fieldDetails.push(
                        response[index].field_details[u].field_text
                      );
                    }
                  }

                  if (response[index].tpl_ques_id == undefined) {
                    break;
                  }

                  this.tplQuesSectData.push({
                    tpl_ques_id: response[index].tpl_ques_id,
                    tpl_id: response[index].tpl_id,
                    qm_ques_id: response[index].qm_ques_id,
                    cc_mapping: response[index].cc_mapping,
                    questionnaire: response[index].questionnaire.name,
                    cc_ques_no: response[index].cc_ques_no,
                    topic: response[index].topic.name,
                    section: response[index].section.name,
                    ques_text: response[index].ques_text,
                    excerpt: response[index].excerpt,
                    expiry_date: response[index].expiry_date + " days",
                    critical_ques:
                      response[index].critical_ques == 1 ? "Yes" : "No",
                    help_text: response[index].help_text,
                    regulations: regulationNames,
                    platforms: platformNames,
                    environments: environmentNames,
                    tags: tagNames,
                    field_details: fieldDetails,
                    controls: controlNames
                  });
                }
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getTemplateQuestion: function() {
      const apiName = "OneAudit";
      const path =
        "/templates/" + this.tpl_id + "/questions" + this.tpl_ques_id;
      this.loading = true;
      CCApiService.get(apiName, path)
        .then(response => {
          this.tplQuesSectData = [];
          if (response != undefined && response.hasOwnProperty("error")) {
            if (response.error == "false") {
              if (response.hasOwnProperty("data") && response.data.length > 0) {
                response = response.data;
                for (var index in response) {
                  var environmentNames = [];
                  var regulationNames = [];
                  var platformNames = [];
                  var tagNames = [];
                  var fieldDetails = [];
                  var controlNames = [];

                  if (response[index].environments != undefined) {
                    for (var i in response[index].environments) {
                      environmentNames.push(
                        response[index].environments[i].env_name
                      );
                    }
                  }

                  if (response[index].regulations != undefined) {
                    for (var j in response[index].regulations) {
                      regulationNames.push(
                        response[index].regulations[j].regln_name
                      );

                      for (var x in response[index].controls) {
                        if (x == response[index].regulations[j].regln_id) {
                          controlNames.push({
                            regln_name:
                              response[index].regulations[j].regln_name,
                            ctrl_no: response[index].controls[x].toString()
                          });
                        }
                      }
                    }
                  }

                  if (response[index].platforms != undefined) {
                    for (var k in response[index].platforms) {
                      platformNames.push(
                        response[index].platforms[k].plat_name
                      );
                    }
                  }

                  if (response[index].tags != undefined) {
                    for (var t in response[index].tags) {
                      tagNames.push(response[index].tags[t].tag_name);
                    }
                  }

                  if (response[index].field_details != undefined) {
                    for (var u in response[index].field_details) {
                      fieldDetails.push(
                        response[index].field_details[u].field_text
                      );
                    }
                  }

                  if (response[index].tpl_ques_id == undefined) {
                    break;
                  }

                  this.tplQuesSectData.push({
                    tpl_ques_id: response[index].tpl_ques_id,
                    tpl_id: response[index].tpl_id,
                    qm_ques_id: response[index].qm_ques_id,
                    cc_mapping: response[index].cc_mapping,
                    questionnaire: response[index].questionnaire.name,
                    cc_ques_no: response[index].cc_ques_no,
                    topic: response[index].topic.name,
                    section: response[index].section.name,
                    ques_text: response[index].ques_text,
                    excerpt: response[index].excerpt,
                    expiry_date: response[index].expiry_date + " days",
                    critical_ques:
                      response[index].critical_ques == 1 ? "Yes" : "No",
                    help_text: response[index].help_text,
                    regulations: regulationNames,
                    platforms: platformNames,
                    environments: environmentNames,
                    tags: tagNames,
                    field_details: fieldDetails,
                    controls: controlNames
                  });
                }
              }
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    viewTplQues(item) {
      if (item == undefined || item.tpl_ques_id == undefined) {
        return;
      }

      const apiName = "OneAudit";
      const path =
        "/templates/" + this.tpl_id + "/questions/" + item.tpl_ques_id;

      CCApiService.get(apiName, path)
        .then(response => {
          if (response != undefined && response.hasOwnProperty("error")) {
            if (
              response.error == "false" &&
              response.hasOwnProperty("data") &&
              response.data.length > 0
            ) {
              response = response.data;
              this.editedItem = Object.assign({}, item);
              this.dialog = true;
            } else if (
              response.error == "true" &&
              response.hasOwnProperty("error_code") &&
              response.error_code != undefined
            ) {
              var message =
                response.hasOwnProperty("data") && response.data != undefined
                  ? ", data: " + response.data.toString()
                  : "";
              this.showMsg(
                "error",
                "Failed. Error code: " + response.error_code + message
              );
            } else {
              this.showMsg("error", "An unexpected error occurred.");
            }
          } else {
            this.showMsg("error", "An unexpected error occurred.");
          }
        })
        .catch(error => {
          this.showMsg("error", error);
        });
    }
  }
};
</script>
